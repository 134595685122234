@font-face {
  font-family: 'Helvetica Neue';
  src: url("font/Helvetica Neue 67 Medium Condensed.otf") format('opentype');
  font-display: swap;
}

.gradient-custom {
  background: #0069f9;
}

.btn-outline-dark {
  color: #fcba00 !important;
  border-color: #fcba00 !important;
}

.btn-outline-dark:hover {
  background-color: #fcba00 !important;
  border-color: #fcba00 !important;
  color: white !important;
}

.form-outline {
  background: white !important;
}

.alert {
  color: red;
}

#root {
  min-height: calc(100vh - 2.5rem);
}

.VictoryContainer {
  min-height: 300px;
  max-height: 1700px;
  height: 100%;
  max-width: 700px;
  width: 100%;
}

.row{
  --bs-gutter-x: 1.25rem !important;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
    list-style-type: none;
}

li {
  margin-bottom: 20px;
}

h2 {
    font-weight : bold;
    font-size: 1.2rem;
}

td > h3 {
  font-size: 1.1rem !important;
}

table {
  margin: 0 auto;
}

.tablecentred {
  text-align: center;
}

h3.tablecentred {
  font-size: 0.8rem !important;
}

td.tablecentred {
  font-size: 0.8rem !important;
}

#dashboardTitle {
  margin-bottom: 30px;
}

#addLink {
  color: black;
}

.pageTitle {
  margin-bottom: 30px;
}

#navContainer {
    width: 100%;
    height: 100px;
    color: white;
    box-shadow: 3px 3px 9px 6px rgba(0, 0, 0, .2);
    margin-bottom: 30px;
    z-index: 2;
}

.bg-secondary {
  background: #fcba00;
}

#StoreDetail {
  padding: 0px 30px 0px 30px;
}

#storeTopups {
  overflow-x: auto;
}

.navbar-toggler {
  margin-right: 10px;
}

/*Form*/
.inlinetitle {
  display: inline-block;
  margin-bottom: 30px;
}

#editButton {
  display: inline-block;
  margin-bottom: 30px;
  margin-left: 30px;
}

#editButton button {
  background: none;
  border: none;
  color: inherit;
  outline: inherit;
}

.formLabel {
  font-size: 1rem;
  color: #212529;
  margin-bottom: 10px;
}

form {
  margin: 0 auto;
}

#editFields {
  margin-bottom: 50px;
}

.form-floating>label {
  padding: 1rem 1rem !important;
}

#maxForm {
  width: 100%;
}

.form-control:disabled {
  margin-bottom: 1rem;
}

#addTopups {
  margin-bottom: 50px;
}

#viewreport {
  width: 70%;
  padding: 1rem 0.75rem;
}

.updating{
  font-weight: 500;
  color: red;
}

#editSubmit {
  width: 100%;
  padding: 1rem 0.75rem;
  margin-bottom: 30px;
}

#printButton {
  width: 14.5%;
  padding: 1rem 0.75rem;
}

#markup {
  margin-bottom: 0rem !important;
}

.invalid-feedback {
  width: auto !important;
}

.submitSpacing {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.col-md-3 {
  margin-bottom: 1rem !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  padding-right: 20px;
}

/*Footer*/

#footerContainer {
  bottom: 0;
  width: 100%;
  display: flex;
  height: 2.5rem;
  box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, .2);
  background-color: #fcba00;
  align-items: baseline;
}

#footerContentContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  color: white;
}

#footerContentContainer > div > p {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

#footerLeft {
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
}

#footerMiddle {
  display: flex;
  justify-content: center;
}

#footerRight {
  display: flex;
  justify-content: center;
}

#footerRight > p > a > img {
  max-width: 150px;
  margin-left: 10px;
}

.card {
  margin-bottom: 30px;
}

#overlay-content .nav-link {
  margin: 15px 15px 15px 15px;
  font-size: 1.1rem;
  font-family: 'Helvetica Neue';
  letter-spacing: 2px;
}

.navbar-brand {
  margin-left: 10px;
}

.offcanvas-header {
  margin-bottom: 70px;
}

#disclaimer {
  font-size: 0.9rem;
}

/*Toggle Switch*/

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/*404*/

#container404 {
  background: #0069f9;
  height:100vh;
}

#text404 {
  text-align: center;
  font-size: 12rem;
  color: white;
  letter-spacing: 2rem;
}

#disclaimer404 {
  color: white;
  text-align: center;
}

#goback {
  color: white;
  text-align: center;
}

/*Responsive*/

@media only screen and (max-width: 570px) {
  .invalid-feedback {
    font-size: 0.7rem !important;
  }

  .form-floating.mb-3 {
    margin-bottom: 25px !important;
  }

  .form-control {
    margin-bottom: 0.5rem !important;
  }
}

@media only screen and (max-width: 700px) {
  table {
    border: 0;
    padding: 5px;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border: 2px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }

  table td {
    display: block;
    font-size: 1rem;
    text-align: right;
  }

  .tablecentred { text-align: right;}

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }

  #mostPopBrand table {
    margin-bottom: 0px;
  }

  #mostPopBrand table tr {
    margin-bottom: 0px;
  }

  #customerCount table {
    margin-bottom: 0px;
  }

  #customerCount table tr {
    margin-bottom: 0px;
  }

  #tradingTerms table {
    margin-bottom: 0px;
  }

  #tradingTerms table tr {
    margin-bottom: 0px;
  }

  #monthSales table {
    margin-bottom: 0px;
  }

  #monthSales table tr {
    margin-bottom: 0px;
  }
}

@media (max-width: 768px) {
  .mobileSpacing {
    margin-bottom: 10px;
  }

  .mobileSpacingBtn {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-collapse {
    align-items: center;
    display: flex;
  }

  #overlay-content .nav-link {
    font-size: 1.4rem !important;
    color: #0069f9 !important;
  }

  .navbar-toggler {
    z-index: 99;
  }
  .navbar-brand {
    z-index: 99;
  }

  .container-xl {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }


}

@media only screen and (min-width: 992px) and (max-width: 1061px) {
  #overlay-content a {
    font-size: 0.88rem !important;
  }
}

@media only screen and (min-width: 992px) {

  #storeMarkups {
    max-width: 900px;
  }

  #selectReport {
    max-width: 900px;
    margin: 0 auto;
  }

  #selectReport .form-control {
    font-size: 0.8rem !important;
  }
}
